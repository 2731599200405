import { gql } from '@apollo/client';

export const CREATE_OFFICE_MUTATION = gql`
	mutation CreateOffice {
		createOffice {
      id
      name
      address
      email
      phone
      fax
		}
	}
`;