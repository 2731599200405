export const uploadFile = async (file: string | Blob, type: string, folder: string = '') => {
  const formData = new FormData();
  formData.append('type', type);
  formData.append('folder', folder);
  formData.append('file', file);

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/files/upload`, 
      { 
        method: 'POST', 
        body: formData 
      }
    );

    if (!response.ok) { throw new Error('Failed to upload file'); }

    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const deleteFile = async (url: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/files/delete`, 
      { 
        method: 'POST', 
        headers: 
          { 
            'Content-Type': 'application/json'
          }, 
        body: JSON.stringify({ url }) 
      }
    );

    if (!response.ok) { throw new Error('Failed to delete file'); }

    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const sendFeedback = async (attachments: string[] | Blob[], user: string, message: string) => {
  const formData = new FormData();
  formData.append('message', message);
  formData.append('user', user);

  attachments.forEach((file) => {
    formData.append('attachments', file);
  });

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/contact/feedback`, 
      { 
        method: 'POST', 
        body: formData 
      }
    );

    if (!response.ok) { throw new Error('Failed to send feedback'); }

    return true;
  } catch (error) {
    console.log(error);
  }
};

export const sendTicket = async (attachments: string[] | Blob[], user: string, message: string) => {
  const formData = new FormData();
  formData.append('message', message);
  formData.append('user', user);

  attachments.forEach((file) => {
    formData.append('attachments', file);
  });

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/contact/support`, 
      { 
        method: 'POST', 
        body: formData 
      }
    );

    if (!response.ok) { throw new Error('Failed to submit ticket'); }

    return true;
  } catch (error) {
    console.log(error);
  }
};

export const formatAsCurrency = (value: number | string) => {
  return value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(typeof value === 'string' ? parseFloat(value) : value) : '';
};

