import apolloClient from '@config/apolloClient';
import { CREATE_OFFICE_MUTATION } from '@mutations//office/create.gpl';
import { UPDATE_OFFICE_MUTATION } from '@mutations//office/update.gpl';
import { DELETE_OFFICE_MUTATION } from '@mutations/office/delete.gpl';
import { OFFICES_QUERY } from '@queries/offices.gpl';

export interface IUpdateOfficePayload {
  id?: number;
  name?: string;
  address?: string;
  email?: string;
  phone?: string;
  fax?: string;
}

export const findOfficesRequest = async () => {
	const { data, errors } = await apolloClient.query({
		query: OFFICES_QUERY
	});

	if (errors) return { errors };

	return { response: data.offices };
};

export const createOfficeRequest = async () => {
	const { data, errors } = await apolloClient.mutate({
		mutation: CREATE_OFFICE_MUTATION
	});

	if (errors) return { errors };

	return { response: data.createOffice };
};

export const updateOfficeRequest = async (payload: IUpdateOfficePayload) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: UPDATE_OFFICE_MUTATION,
    variables: { input: { ...payload }}
	});

	if (errors) return { errors };

	return { response: data.updateOffice };
};

export const deleteOfficeRequest = async (id: number) => {
	const { data, errors } = await apolloClient.mutate({
		mutation: DELETE_OFFICE_MUTATION,
    variables: { id }
	});

	if (errors) return { errors };

	return { response: data.removeOffice };
};