import { DatePicker, Divider, Input, Select, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';

const { Title } = Typography;

const Documents = () => {
 return (
  <Content className='px-4 py-8'>
    <Title level={5} className='mt-[-20px] mb-[-20px]'>MLS Information</Title>
    <Divider className='mt-0' />
    <div className='flex rounded-md border-gray-100 border-2 w-min p-2 mt-[-10px] mb-4'>
      <div className='flex flex-col items-center mx-2 '>
        <span className='text-xs whitespace-nowrap'>MLS Public ID</span>
        <span className='text-lg'>94940</span>
      </div>
      <div className='flex flex-col items-center mx-2'>
        <span className='text-xs'>MLS Name</span>
        <span className='text-lg'>WASATCH</span>
      </div>
    </div>
    <Title level={5} className='mb-[-20px]'>License</Title>
    <Divider className='mt-0' />
    <div className='w-full grid grid-cols-9 grid-rows-2 gap-4 mb-8 mt-[-10px]'>
      <div className='row-span-2 col-span-2'>
        <div className='text-xs'>Name on License</div>
        <Input className='w-full' />
      </div>
      <div className='row-span-2 col-span-1'>
        <div className='text-xs'>State</div>
        <Select
          showSearch
          placeholder='Select a State'
          optionFilterProp='label'
          className='w-full'
        />
      </div>
      <div className='row-span-2 col-span-2'>
        <div className='text-xs'>License #</div>
        <Input className='w-full' />
      </div>
      <div className='row-span-2 col-span-2'>
        <div className='text-xs'>Type</div>
        <Select
          showSearch
          placeholder='Select type'
          optionFilterProp='label'
          className='w-full'
        />
      </div>
      <div className='row-span-2 col-span-1'>
        <div className='text-xs'>Issue Date</div>
        <DatePicker className='w-full' />
      </div>
      <div className='row-span-2 col-span-1'>
        <div className='text-xs'>Expiration Date</div>
        <DatePicker className='w-full' />
      </div>
    </div>
    <Title level={5} className='mb-[-20px] mt-[-15px]'>W9</Title>
    <Divider className='mt-0' />
    <div className='w-full grid grid-cols-9 grid-rows-2 gap-4 mb-8 mt-[-10px]'>
      <div className='row-span-2 col-span-2'>
        <div className='text-xs'>W9 Name</div>
        <Input className='w-full' />
      </div>
      <div className='row-span-2 col-span-2'>
        <div className='text-xs'>W9 Business Name</div>
        <Input className='w-full' />
      </div>
      <div className='row-span-2 col-span-4'>
        <div className='text-xs'>W9 Address</div>
        <Input className='w-full' />
      </div>
    </div>
    <Title level={5} className='mb-[-20px]'>Documents</Title>
    <Divider className='mt-0' />
  </Content>

 );
}

export default Documents;