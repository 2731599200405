import { Typography, Calendar as CalendarAntd, Badge, BadgeProps } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { Dayjs } from 'dayjs';

const { Title } = Typography;

const Calendar = () => {

  const getListData = (value: Dayjs) => {
    let listData: { type: string; content: string }[] = []; 
    switch (value.date()) {
      case 8:
        listData = [
          { type: 'warning', content: 'This is warning event.' },
          { type: 'success', content: 'This is usual event.' },
        ];
        break;
      case 10:
        listData = [
          { type: 'warning', content: 'This is warning event.' },
          { type: 'success', content: 'This is usual event.' },
          { type: 'error', content: 'This is error event.' },
        ];
        break;
      case 15:
        listData = [
          { type: 'warning', content: 'This is warning event' },
          { type: 'success', content: 'This is very long usual event and will expand to 2 lines.' },
          { type: 'error', content: 'This is error event 1.' },
          { type: 'error', content: 'This is error event 2.' },
          { type: 'error', content: 'This is error event 3.' },
          { type: 'error', content: 'This is error event 4.' },
        ];
        break;
      default:
    }
    return listData || [];
  };

  const _onCellRender = (value: Dayjs) => {
    const listData = getListData(value);
    return (
      <ul className='events'>
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type as BadgeProps['status']} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Content className='bg-white p-4 m-2'>
      <Title level={5} className='ml-4'>Calendar</Title>
      <CalendarAntd cellRender={_onCellRender}/>
    </Content>
  );
};

export default Calendar;
