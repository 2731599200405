/* eslint-disable react-hooks/exhaustive-deps */
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Form, Input, message, Popconfirm, Select, Table, TableProps } from 'antd';
import MaskedInput from 'antd-mask-input';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';

interface EditableTableProps<T> {
  source: T[];
  columns: ColumnsType<T>;
  actions?: any;
  name: string;
  loading: boolean;
  selection: any;
  updateFn: (row: T) => void;
  deleteFn: (id: number) => void;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'phone' | 'select' | 'text';
  validationRules: any;
  record: any;
  index: number;
  options: any;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  validationRules,
  options,
  ...restProps
}) => {
  const inputNode = inputType === 'phone' ? <MaskedInput mask={'(000) 000 0000'} /> : inputType === 'select' ? <Select options={options}/> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item className='m-0' name={dataIndex} rules={validationRules ?? []}>
          {inputNode}
        </Form.Item>
      ) : inputType === 'select' && options ? (
        options.find((option: any) => option.value === record[dataIndex])?.label || children
      ) : (
        children
      )}
    </td>
  );
};

const CustomHeaderCell = (props: any) => {
  return (
    <th {...props} style={{ padding: '0.5em 1em 0.5em 1em' }}>
      {props.children}
    </th>
  );
}

function EditableTable<T extends object>({
  source,
  columns,
  actions,
  name,
  loading,
  selection,
  updateFn,
  deleteFn
}: EditableTableProps<T>) {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [editingKey, setEditingKey] = useState(0);

  useEffect(() => {
    if(selection) {
      edit(selection);
    }
  }, [selection]);

  const isEditing = (record: any) => record.id === editingKey;

  const actionColumn = [{
    title: 'Actions',
    dataIndex: 'operation',
    render: (_: any, record: any) => {
      const editable = isEditing(record);
      return editable ? (
        <span className='flex flex-row'>
          <CheckOutlined onClick={() => save(record.id!)} className='mr-4' title='Save'/>
          <Popconfirm title='Discard changes?' icon={null} okText='Yes' cancelText='No' onConfirm={cancel}>
            <CloseOutlined title='Cancel' />
          </Popconfirm>
        </span>
      ) : (
        <span className='flex flex-row'>
          {actions && actions(record)}
          <EditOutlined onClick={() => edit(record)} className='mr-4' title='Edit'/>
          <Popconfirm title={`Delete this ${name}?`} icon={null} okText='Yes' cancelText='No' onConfirm={() => deleteFn(record.id!)}>
            <DeleteOutlined title='Delete'/>
          </Popconfirm>
        </span>
      );
    },
  }];

  const edit = (record: any) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id!);
  };

  const cancel = () => {
    setEditingKey(0);
  };

  const save = async (id: number) => {
    try {
      const row = (await form.validateFields()) as any;
      row.id = id;
      updateFn(row);
      setEditingKey(0);
      messageApi.open({ type: 'success', content: `${name} information updated correctly` });
    } catch {
      messageApi.open({ type: 'error', content: 'Please, correct the errors and try again' });
    }
  };

  const mergedColumns: TableProps<T>['columns'] = [...columns, ...actionColumn].map((col: any) => {
    if (!col.editable) return col;
    
    return {
      ...col,
      onCell: (record: T) => ({
        record,
        inputType: col.inputType ?? 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        validationRules: col.validationRules ?? null,
        editing: isEditing(record),
        options: col.options,
      }),
    };
  });

  return (
    <>
      {contextHolder}
      <Form form={form} component={false}>
        <Table<T>
          components={{ body: { cell: EditableCell }, header: { cell: CustomHeaderCell } }}
          bordered
          loading={loading}
          dataSource={source}
          columns={mergedColumns}
          rowClassName='editable-row'
          pagination={{ onChange: cancel }}
        />
      </Form>
    </>
  )
};

export default EditableTable;
