import { UploadOutlined } from '@ant-design/icons';
import { AuthContext } from '@shared/context/auth';
import { sendTicket } from '@shared/utils/functions';
import { Drawer, Input, Button, Upload } from 'antd';
import { useContext, useState } from 'react';

const SupportForm = ({ openSupport, setOpenSupport, sendTicketFn }: any) => {
  const { authState } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState<any[]>([]);

  const handleBeforeUpload = (file: any) => {
    setAttachments((prevAttachments) => [...prevAttachments, file]);
    return false;
  };

  const handleRemove = (file: any) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((attachment) => attachment.uid !== file.uid)
    );
  };

  const handleSend = async () => {
    setLoading(true);

    await sendTicket(attachments, authState.user.email, message);

    setAttachments([]);
    setMessage('');
    setLoading(false);
    sendTicketFn();
  }

  return (
    <Drawer
      title='Submit a Support Ticket'
      width={420}
      onClose={() => setOpenSupport(false)}
      open={openSupport}
      styles={{ body: { paddingBottom: 80, display: 'flex', flexDirection: 'column' }}}
    >
      <span>Please describe your issue below. Please be as detailed as you can, and include screenshots if possible.</span>
      <Input.TextArea rows={5} className='mt-4' value={message} onChange={(e) => setMessage(e.target.value)}/>
      <Upload
        className='upload-full-width'
        multiple
        fileList={attachments}
        beforeUpload={handleBeforeUpload}
        onRemove={handleRemove}
      >
        <Button className='mt-4' icon={<UploadOutlined />}>Attach screenshots here</Button>
      </Upload>
      <Button type='primary' className='mb-4 mt-4' loading={loading} onClick={handleSend} disabled={!message}>
        Submit Ticket
      </Button>
    </Drawer>
  );
};

export default SupportForm;