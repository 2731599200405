import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
	mutation Login($input: LoginInput!) {
		login(input: $input) {
      jwtBearer
      jwtRefresh
      user {
        id
        fullName
        email
        password
        birthDate
        hireDate
        jobTitle
        officeId
        role
        bio
        phone
        fax
        avatar
        social
      }
		}
	}
`;