type DashboardStatProps = {
  stats: number;
  name: string;
  money?: boolean;
}

const DashboardStat = ({ stats, name, money = false}: DashboardStatProps) => {

  const formatNumber = (value: number, showMoney: boolean) => {
    const preffix = showMoney ? '$' : '';

    if (value >= 1_000_000_000) {
      return preffix + (value / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (value >= 1_000_000) {
      return preffix + (value / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    // if (value >= 1_000) {
    //   return preffix + (value / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
    // }
    return preffix + value.toLocaleString();
  }

	return (
    <div className='flex flex-col items-center'>
      <span className='text-5xl font-bold mb-1 text-distinctive-gold'>{`${formatNumber(stats, money)}`}</span>
      <span className='text-lg font-bold text-distinctive-text uppercase'>{name}</span>
    </div>
	);
};

export default DashboardStat;