import { useContext } from 'react';
import { IRegisterPayload, loginRequest, registerRequest } from '@services/auth.service';
import { Form, Input, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import { AuthActions, AuthContext } from '@shared/context/auth';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';

const Register = () => {
	const { dispatch } = useContext(AuthContext);
	const [messageApi, contextHolder] = message.useMessage();
	
	const onFinish = (values: IRegisterPayload) => {
		registerRequest(values)
			.then((resp) => {
				if(!resp.errors) {
					loginRequest({ email: values.email, password: values.password })
						.then((res: any) => {
							dispatch({ action: AuthActions.LOGIN, data: { token: res.jwtBearer, refresh: res.jwtRefresh, user: res.user }});
						});
				} else {
					messageApi.open({ type: 'error', content: `${resp.errors[0].message}` });
				}
			})
	};

	return (
		<>
			{contextHolder}
			<Form initialValues={{ remember: true }} onFinish={onFinish} layout='vertical'>
				<Form.Item name='fullName' label='Full Name' rules={[{ required: true,	message: 'Please input your Full Name' }]}>
					<Input prefix={<UserOutlined className='site-form-item-icon mr-2' />} placeholder='Full Name' />
				</Form.Item>
				<Form.Item name='email' label='Email' rules={[{ required: true, message: 'Please input your Email' }]}>
					<Input prefix={<MailOutlined className='site-form-item-icon mr-2' />} placeholder='Email' />
				</Form.Item>
				<Form.Item name='password' label='Password' rules={[{ required: true, message: 'Please input your Password' }, { min: 8, message: 'Password must be at least 8 characters' }]}>
					<Input prefix={<LockOutlined className='site-form-item-icon mr-2' />} type='password' placeholder='Password' />
				</Form.Item>
				<Form.Item>
					<Button type='primary' htmlType='submit' block>Register</Button>
					<div className={'text-center mt-2'}>Already have an account?{' '}<Link to={'/auth/login'} className='underline'>login now!</Link></div>
				</Form.Item>
			</Form>
		</>
	);
};

export default Register;
