/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Input, Select } from 'antd';

import FacebookIcon from '@assets/icons/facebook.svg';
import InstagramIcon from '@assets/icons/instagram.svg';
import LinkedIcon from '@assets/icons/linkedin.svg';
import TiktokIcon from '@assets/icons/tiktok.svg';
import YoutubeIcon from '@assets/icons/youtube.svg';
import TwitterIcon from '@assets/icons/twitter.svg';
import TwitchIcon from '@assets/icons/twitch.svg';
import { useState } from 'react';
import { Social } from '@shared/utils/enums';

const { Option } = Select;

const SocialInput = ({ media, user, index, updateFn, removeFn }: any) => {
  const [platform, setPlatform] = useState(media);
  const [username, setUsername] = useState(user);

  const _onPlatformSelect = (value: Social) => {
    setPlatform(value);
    updateFn(index, { platform: value, username });
  };

  const _onUsernameChange = (e: any) => {
    setUsername(e.target.value);
    updateFn(index, { platform, username: e.target.value });
  };

  return (
    <div className='flex flex-col items-center'>
      <Avatar icon={<UserOutlined />} size={70} className='self-center bg-distinctive-text mb-4'></Avatar>
      <div className='flex flex-row'>
        <Input className='w-full' addonBefore={
          <Select
            value={platform}
            onChange={_onPlatformSelect}
            style={{ width: 60 }}
          >
            <Option value={Social.INSTAGRAM}><img src={InstagramIcon} alt={Social.INSTAGRAM} /></Option>
            <Option value={Social.TWITTER}><img src={TwitterIcon} alt={Social.TWITTER} /></Option>
            <Option value={Social.FACEBOOK}><img src={FacebookIcon} alt={Social.FACEBOOK} /></Option>
            <Option value={Social.YOUTUBE}><img src={YoutubeIcon} alt={Social.YOUTUBE} /></Option>
            <Option value={Social.LINKEDIN}><img src={LinkedIcon} alt={Social.LINKEDIN} /></Option>
            <Option value={Social.TIKTOK}><img src={TiktokIcon} alt={Social.TIKTOK} /></Option>
            <Option value={Social.TWITCH}><img src={TwitchIcon} alt={Social.TWITCH} /></Option>
          </Select>}
          placeholder='Username'
          value={username}
          onChange={_onUsernameChange}
        />
        <Button
          shape='circle'
          icon={<DeleteOutlined />}
          onClick={() => removeFn(index)}
          style={{ backgroundColor: 'gray', color: 'white' }}
          className='mx-2 p-2 rounded-full'
        />
      </div>
    </div>
  );
};

export default SocialInput;