import { Divider, Input, Typography } from 'antd';
import MaskedInput from 'antd-mask-input';
import { Content } from 'antd/es/layout/layout';

const { Title } = Typography;

const Broker = () => {
  
  return (
    <>
      <Content className='px-4 py-8'>
        <Title level={5} className='mt-[-20px] mb-[-20px]'>General Information</Title>
        <Divider className='mt-0' />
        <div className='w-full grid grid-cols-12 grid-rows-2 gap-4 mb-8'>
          <div className='col-span-5'>
            <div className='text-xs'>Brokerage Name</div>
              <Input 
                className='w-full' 
                placeholder='Brokerage Name cannot be empty' 
                // status={formData.fullName ? '' : 'error'} 
                // value={formData.fullName} 
                // onChange={(e) => _onChange('fullName', e.target.value)}
              />
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Primary Phone</div>
            <MaskedInput 
              mask={'(000) 000 0000'} 
              className='w-full' 
              // value={formData.phone}
              // onChange={(e: any) => _onChange('phone', e.target.value)}
            />
          </div>
          <div className='col-span-2'>
            <div className='text-xs'>Secondary Phone</div>
            <MaskedInput 
              mask={'(000) 000 0000'} 
              className='w-full' 
              // value={formData.fax}
              // onChange={(e: any) => _onChange('fax', e.target.value)}
            />
          </div>
        </div>
      </Content>
    </>
  );
};

export default Broker;