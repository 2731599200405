import gql from 'graphql-tag';

export const VALIDATE_TOKEN_MUTATION = gql`
	mutation ValidateToken($input: ValidateTokenInput!) {
		validateToken(input: $input) {
      message
      type
		}
	}
`;
