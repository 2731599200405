import { Layout } from 'antd';

const User = () => {
  return (
    <Layout>
      Users
    </Layout>
  );
};

export default User;
