import { useContext, useState } from 'react';
import { Avatar, Layout, Menu, message, Popover } from 'antd';
import { CalendarOutlined, DesktopOutlined, FileDoneOutlined, InboxOutlined, MessageOutlined, QuestionCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import FeedbackForm from '@components/FeedbackForm';
import { AuthActions, AuthContext } from '../context/auth';

import logoImg from '@assets/images/logo.png';
import signImg from '@assets/images/sign.png';
import feedback from '@assets/images/feedback.png';
import support from '@assets/images/support.png';
import SupportForm from '@components/SupportForm';
import { Role } from '@shared/utils/enums';

const { Header, Content, Sider } = Layout;

const DashboardLayout = ({ children }: any) => {
	const { authState, dispatch } = useContext(AuthContext);
	const [messageApi, contextHolder] = message.useMessage();

	const [collapsed, setCollapsed] = useState(false);
	const [openFeedback, setOpenFeedback] = useState(false);
	const [openSupport, setOpenSupport] = useState(false);

	const _onLogoutPress = () => {
		dispatch({ action: AuthActions.LOGOUT, data: { }});
	};

	const sendFeebackFn = () => {
		messageApi.open({ type: 'success', content: 'Thank you for submitting feedback!' });
		setOpenFeedback(false);
	}

	const sendTicketFn = () => {
		messageApi.open({ type: 'success', content: 'Ticket submitted! We will contact you from support' });
		setOpenSupport(false);
	}

	const openSupportFn = (e: any) => {
		e.preventDefault();
		
		setOpenFeedback(false);
		setOpenSupport(true);
	}

	return (
		<>
			{contextHolder}
			<Layout className={'h-screen'}>
				<Layout className='site-layout flex'>
					<Sider
						collapsible
						collapsed={collapsed}
						onCollapse={(collapse) => setCollapsed(collapse)}
						className='bg-distinctive-menu border-r-2 shadow-md py-5 border-distinctive-text'
					>
						<Link to='/' className='logo'>
						{collapsed ? 
							<img src={signImg} alt='logo' className='h-24 justify-self-center pb-5' />
						: 
							<img src={logoImg} alt='logo' className='w-40 justify-self-center pb-5' />
						}
						</Link>
						<Menu mode='inline' className='bg-distinctive-menu'>
							{Object.keys(Role).includes(authState.user.role) && 
								<Menu.Item key='1' icon={<DesktopOutlined />}>
									<Link to='/admin'>Admin</Link>
								</Menu.Item>
							}
							<Menu.Item key='2' icon={<MessageOutlined />}>
								Message Board
							</Menu.Item>
							<Menu.Item key='3' icon={<InboxOutlined />}>
								Private Message
							</Menu.Item>
							<Menu.Item key='4' icon={<CalendarOutlined />}>
								<Link to='/calendar'>Calendar</Link>
							</Menu.Item>
							<Menu.Item key='5' icon={<FileDoneOutlined />}>
								Report
							</Menu.Item>
							<Menu.Item key='6' icon={<QuestionCircleOutlined />}>
								Help
							</Menu.Item>
						</Menu>
					</Sider>
					<Layout className='flex flex-col flex-1'>
						<Header className='bg-distinctive-back z-10 flex px-6 justify-end'>
							<div className={'flex gap-4 items-center'}>
								<img onClick={() => setOpenSupport(true)} src={support} alt='support' title='Contact Support' className='h-[22px] mt-[-10px]' />
								<img onClick={() => setOpenFeedback(true)} src={feedback} alt='feeback' title='Leave Suggestion' className='h-[25px] mt-[-12px]' />
								<Link to='/account' className='text-distinctive-text mt-[-2px]'>{authState.user.fullName}</Link>
								<Popover placement='bottomLeft' content={<Link to='#' className='text-distinctive-text mt-[-2px]' onClick={_onLogoutPress}>Log Out</Link>}>
									<Link to='/account' className='logo'>
										<Avatar src={authState.user.avatar} icon={<UserOutlined />} className='cursor-pointer hover:opacity-90 self-center bg-distinctive-text mt-[-5px]'></Avatar>
									</Link>
								</Popover>
							</div>
						</Header>
						<Content className='bg-distinctive-back flex-1 overflow-auto'>{children}</Content>
						<FeedbackForm openFeedback={openFeedback} setOpenFeedback={setOpenFeedback} sendFeebackFn={sendFeebackFn} openSupportFn={openSupportFn}></FeedbackForm>
						<SupportForm openSupport={openSupport} setOpenSupport={setOpenSupport} sendTicketFn={sendTicketFn}></SupportForm>
					</Layout>
				</Layout>
			</Layout>
		</>
	);
};

export default DashboardLayout;
