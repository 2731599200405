import { gql } from '@apollo/client';

export const UPDATE_OFFICE_MUTATION = gql`
	mutation UpdateOffice($input: UpdateOfficeInput!)  {
		updateOffice(updateOfficeInput: $input) {
      id
      name
      address
      email
      phone
      fax
		}
	}
`;