import { useContext } from 'react';
import { AuthActions, AuthContext } from '@shared/context/auth';
import { loginRequest } from '@services/auth.service';
import { Form, Input, Button, Checkbox, Row, message } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const Login = () => {
	const { dispatch } = useContext(AuthContext);
	
	const [form] = Form.useForm();
	const [messageApi, contextHolder] = message.useMessage();

	const onFinish = () => {
		const formData = form.getFieldsValue();

		loginRequest({ email: formData.email, password: formData.password })
			.then((res: any) => {
				if(!res.errors) {
					dispatch({ action: AuthActions.LOGIN, data: { token: res.jwtBearer, refresh: res.jwtRefresh, user: res.user }});
				} else {
					messageApi.open({ type: 'error', content: `Login failed: ${res.errors[0].message}` });
				}
			});
	};

	return (
		<>
			{contextHolder}
			<Form form={form} initialValues={{ remember: true }} onFinish={onFinish}>
				<Form.Item name='email' rules={[{ required: true, message: 'Please input your Email' }]}>
					<Input prefix={<MailOutlined className='site-form-item-icon mr-2' />} placeholder='Email'/>
				</Form.Item>
				<Form.Item name='password' rules={[{ required: true, message: 'Please input your Password!' }, { min: 8, message: 'Password must be at least 8 characters' }]}>
					<Input prefix={<LockOutlined className='site-form-item-icon mr-2' />} type='password' placeholder='Password'/>
				</Form.Item>
				<Row justify={'space-between'} className={'mb-4'}>
					<Form.Item name='remember' valuePropName='checked' noStyle>
						<Checkbox>Remember me</Checkbox>
					</Form.Item>
					<Link to={'/auth/recover'}>Forgot password</Link>
				</Row>
				<Form.Item>
					<Button type='primary' htmlType='submit' block>Log in</Button>
					<div className={'text-center mt-2'}>Or <Link to={'/auth/register'} className='underline'>register now!</Link></div>
				</Form.Item>
			</Form>
		</>
	);
};

export default Login;
