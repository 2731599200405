/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router';
import { AuthContext } from '@shared/context/auth';
import Login from './Login';
import Register from './Register';
import logoImage from '@assets/images/logo.png';
import { Card, Row } from 'antd';
import Reset from './Reset';
import Recover from './Recover';

const Auth = () => {
	const navigate = useNavigate();
	const { authState } = useContext(AuthContext);

	useEffect(() => {
		if (authState.token && authState.user) {
			navigate('/');
		}
	}, [authState]);

	return (
		<div className={'w-screen h-screen flex items-center justify-center'}>
			<Card style={{ width: 400 }} className='shadow-2xl bg-distinctive-menu'>
				<Row justify='center'>
					<img src={logoImage} alt='Logo' className='h-40 m-6'/>
				</Row>
				<div className='mt-6'>
					<Routes>
						<Route path='login' element={<Login />} />
						<Route path='register' element={<Register />}/>
						<Route path='reset' element={<Reset />}/>
						<Route path='recover' element={<Recover />}/>
					</Routes>
				</div>
			</Card>
		</div>
	);
};

export default Auth;
