import { MailOutlined } from '@ant-design/icons';
import { forgotPasswordRequest } from '@shared/services/auth.service';
import { Form, Input, Button, message } from 'antd';

const Recover = () => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  
  const onFinish = async () => {
    const formData = form.getFieldsValue();

    await forgotPasswordRequest(formData.email)
      .then((resp: any) => {
        messageApi.open({ type: resp.response.type, content: resp.response.message });
      });
  };

  return (
    <>
      {contextHolder}
      <Form form={form} initialValues={{ remember: true }} onFinish={onFinish} layout='vertical'> 
        <Form.Item className='mb-8' name='email' label='Email' rules={[{ required: true, message: 'Please input your Email' }]}>
          <Input prefix={<MailOutlined className='site-form-item-icon mr-2' />} placeholder='Email' />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' block>Send Email reset Link</Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Recover;
