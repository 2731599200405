import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { toast } from 'react-toastify';
import { GraphQLError } from 'graphql';
import { clearLocalStorage, refreshTokenRequest } from '@services/auth.service';

const REACT_APP_API_URL = process.env[`REACT_APP_API_URL`];
const REACT_APP_GRAPH_ENDPOINT = process.env[`REACT_APP_GRAPH_ENDPOINT`];

const httpLink = createHttpLink({ uri: `${REACT_APP_API_URL}/${REACT_APP_GRAPH_ENDPOINT}`});

const authLink = setContext(async (_, { headers }) => {
  const token = JSON.parse((await localStorage.getItem('token')) || '{}');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const error = onError(({ graphQLErrors, networkError, response }) => {
  try {
    if (graphQLErrors && graphQLErrors[0] !== null) {
      const tempError: GraphQLErrorExtended[] = JSON.parse(JSON.stringify(graphQLErrors));

      const errorMessage = tempError.map(
        ({ message, details, internalCode }) => {
          if (internalCode === 4001) {
            const refresh = localStorage.getItem('refresh');

            if (refresh) {
              refreshTokenRequest(JSON.parse(refresh))
                .then((resp: any) => {
                  const { jwtBearer, user, jwtRefresh } = resp;
                  localStorage.setItem('token', JSON.stringify(jwtBearer));
                  localStorage.setItem('refresh', JSON.stringify(jwtRefresh));
                  localStorage.setItem('user', JSON.stringify(user));
                  window.location.reload();
                })
                .catch(() => {
                  clearLocalStorage();
                  window.location.reload();
                });
            } else {
              clearLocalStorage();
              window.location.reload();
            }
          }

          return `${message}. ${details.join('. ')}.`;
        }
      );

      toast(errorMessage.join('. '), { type: 'error' });
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  } catch (error) {}
});

const cache = new InMemoryCache({});

const apolloClient = new ApolloClient({
  link: error.concat(authLink.concat(httpLink)),
  cache: cache,
  defaultOptions: {
    mutate: { errorPolicy: 'all' },
  },
});

export default apolloClient;

export interface GraphQLErrorExtended extends GraphQLError {
  details: string[];
  internalCode: number;
}
