import { UploadOutlined } from '@ant-design/icons';
import { AuthContext } from '@shared/context/auth';
import { sendFeedback } from '@shared/utils/functions';
import { Drawer, Input, Button, Upload } from 'antd';
import { useContext, useState } from 'react';

const FeedbackForm = ({ openFeedback, setOpenFeedback, sendFeebackFn, openSupportFn }: any) => {
  const { authState } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState<any[]>([]);

  const handleBeforeUpload = (file: any) => {
    setAttachments((prevAttachments) => [...prevAttachments, file]);
    return false;
  };

  const handleRemove = (file: any) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((attachment) => attachment.uid !== file.uid)
    );
  };

  const handleSend = async () => {
    setLoading(true);

    await sendFeedback(attachments, authState.user.email, message);

    setAttachments([]);
    setMessage('');
    setLoading(false);
    sendFeebackFn();
  }

  return (
    <Drawer
      title='Leave a Suggestion'
      width={420}
      onClose={() => setOpenFeedback(false)}
      open={openFeedback}
      styles={{ body: { paddingBottom: 80, display: 'flex', flexDirection: 'column' }}}
    >
      <span>We want to hear from you! Leave a comment below to let us know how we can improve the website or the business!</span>
      <Input.TextArea rows={5} className='mt-4' value={message} onChange={(e) => setMessage(e.target.value)}/>
      <Upload
        className='upload-full-width'
        multiple
        fileList={attachments}
        beforeUpload={handleBeforeUpload}
        onRemove={handleRemove}
      >
        <Button className='mt-4' icon={<UploadOutlined />}>Attach files here</Button>
      </Upload>
      <Button type='primary' className='mb-4 mt-4' loading={loading} onClick={handleSend} disabled={!message}>
        Send Suggestion
      </Button>
      <span>Experiencing an issue with the app? Submit a <a href='/' className='underline' onClick={openSupportFn}>support ticket here.</a></span>
    </Drawer>
  );
};

export default FeedbackForm;